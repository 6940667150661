import { ForwardedRef, forwardRef, HTMLProps, useState } from "react";
import { ReactComponent as InfoIcon } from "assets/information-circle.svg";
import classNames from "classnames";
import { ConfirmDialog } from "components/Dialog/ConfirmDialog";
import Fade from "components/Transition/Fade";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'checked'> {
    label: string;
    checked: boolean;
    error?: string;
    tooltipContent?: string;
    id?: string;
    onChange: (checked: boolean) => void;
}

const CheckboxPlain = (
    {
        label,
        checked,
        error,
        tooltipContent,
        id,
        onChange,
        ...inputAttributes
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const { t } = useModifiedTranslation();

    return (
        <>
            <label
                id={!id ? undefined : `${id}-label`}
                className={classNames("flex gap-2 w-full text-sm font-normal", {
                    "text-red-700": !!error,
                })}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    id={id}
                    ref={ref}
                    className={classNames(
                        "rounded border-gray-300 bg-gray-50 text-primary-400 focus:border-primary-400 p-4",
                        {
                            "text-red-900 placeholder-red-700 bg-red-50 focus:border-red-500 border-red-500":
                                !!error,
                            "cursor-not-allowed": inputAttributes.disabled,
                        }
                    )}
                    {...inputAttributes}
                />

                <div className="flex gap-x-1 items-center">
                    {label}
                    {tooltipContent && (
                        <>
                            <div
                                id={!id ? undefined : `${id}-tooltipDialogButton`}
                                className="cursor-help"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmDialogOpen(true);
                                }}
                            >
                                <InfoIcon/>
                            </div>
                        </>
                    )}
                </div>

                <Fade>
                    {error && (
                        <p
                            id={!id ? undefined : `${id}-error`}
                            className="mt-1 text-xs text-red-600"
                        >
                            {error}
                        </p>
                    )}
                </Fade>
            </label>
            {tooltipContent && (
                <ConfirmDialog
                    id={!id ? undefined : `${id}-tooltipDialog`}
                    title={t("component.select.dialogTitle")}
                    description={tooltipContent}
                    cancelButtonText={t("component.select.dialogCloseButtonLabel")}
                    isOpen={confirmDialogOpen}
                    setIsOpen={setConfirmDialogOpen}
                />
            )}
        </>
    );
};

export const Checkbox = forwardRef(CheckboxPlain);
