import { gql, useQuery } from "@apollo/client";

const GET_CONTACTS_QUERY = gql`
  query ($extId: String, $reportDate: String) {
    contacts(extId: $extId) {
        portfolioReport(
          reportDate: $reportDate
          calculateExpectedAmountBasedOpenTradeOrders: true
          calculateExpectedAmountBasedUnsettledTransactions: true
          useLatestPositionsAmount: true
          adjustPositionsBasedOnOpenTradeOrders: false
        ) {
          accountItems {
            amountBeforeUnsettledTransactions
            amountFromOpenTradeOrders
            amountFromSentTradeOrders
            amountAfterOpenTradeOrders
            balanceAccCurr
            currencyId
            accountName
                currency {
              securityCode
            }
          }
        }
        name
        externalId
        contactId
      }
  }
`;

interface AccountItem {
  accountName: string;
  currency: {
    securityCode: string;
  };
  balanceAccCurr: number;
  amountFromOpenTradeOrders: number;
  amountFromSentTradeOrders: number;
}


interface Contact {
  portfolioReport: {
    accountItems: AccountItem[];
  }
}

interface ContactsData {
  contacts: Contact[];
}

export interface Balance {
  trading: { currency: string, amount: number }[];
  withdrawal: { currency: string, amount: number }[];
}
interface Balances { 
  [key: string]: Balance
}

export const useGetAvailableBalances = (
  extId: string | undefined,
  reportDate?: string
) => {
  if (!reportDate) {
    reportDate = new Date().toISOString().split('T')[0];
  }

  const { loading, error, data } = useQuery<ContactsData>(GET_CONTACTS_QUERY, {
    variables: {
      extId,
      reportDate,
    },
  });

  const balances:Balances = {}

  data?.contacts[0]?.portfolioReport?.accountItems.forEach((item: AccountItem) => {
    const accountNumber = item.accountName.split('-')[0];
    const currency = item.currency.securityCode;
    const tradingAmount = item.balanceAccCurr + item.amountFromOpenTradeOrders;
    const withdrawalAmount = item.balanceAccCurr + item.amountFromSentTradeOrders;

    if (!balances[accountNumber]) {
      balances[accountNumber] = { 
        trading: [],
        withdrawal: [] 
      };
    }

    balances[accountNumber].trading.push({ currency, amount: tradingAmount });
    balances[accountNumber].withdrawal.push({ currency, amount: withdrawalAmount });
  });

  return {
    loading,
    error,
    data,
    balances,
  };
};


