import { useDetectActiveUserType } from "hooks/useDetectActiveUserType";
import { Navigate, useRoutes } from "react-router-dom";
import {
  canPortfolioTrade,
  PermissionMode,
  usePermission,
  useIsCBLAAMPortfolio
} from "services/permissions/usePermission";
import { NavTabLayout } from "../NavTabLayout";
import { NavTabPath } from "./types";

interface NavTabRoutesProps {
  routes: NavTabPath[];
}

export const NavTabRoutes = ({ routes }: NavTabRoutesProps) => {

  const isCBLAAMPortfolio = useIsCBLAAMPortfolio();
  const { isAnotherUser } = useDetectActiveUserType();

  const canTrade = usePermission(
    PermissionMode.SELECTED_ANY,
    canPortfolioTrade
  );

  const filteredRoutes = routes.filter((route) => {
    if (route.path === "trading") {
      return canTrade && !isCBLAAMPortfolio && !isAnotherUser;
    }

    return true;
  });

  return useRoutes([
    {
      path: "",
      element: <NavTabLayout routes={filteredRoutes} />,
      children: filteredRoutes,
    },
    {
      path: "*",
      element: <Navigate to="overview" replace />,
    },
  ]);
};
