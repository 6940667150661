import { useReducer, useState } from "react";
import { ApolloError, gql, OperationVariables, useQuery } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { Option } from "components/Select/Select";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { tradableTag, cblPortalGroupTag } from "services/permissions/usePermission";
import { getBackendTranslation } from "utils/backTranslations";
import { SecurityTypeCode } from "../holdings/types";
import { getFetchPolicyOptions } from "../utils";

const TRADABLE_SECURITIES_QUERY = gql`
query GetTradableSecurities($status: String, $countryCode: String, $securityType: String, $name: String, $tradableTag: [String]) {
  securities(
    tags: $tradableTag
    countryCode: $countryCode
    securityType: $securityType
    name: $name
    status: $status
  ) {
    id
    name
    namesAsMap
    isinCode
    securityCode
    updateCode3
    maturityDate
    classType1 {
      value
      __typename
    }
    country {
      id
      name
      code
      namesAsMap
      __typename
    }
    type {
      id
      name
      code
      namesAsMap
      __typename
    }
    currency {
      id
      securityCode
      __typename
    }
    managementFee
    managementFeePercentage
    minTradeAmount
    amountDecimalCount
    __typename
  }
}
`;

const TRADABLE_SECURITIES_CBL_QUERY = gql`
query GetTradableSecurities($status: String, $tradableTag: [String]) {
  securities(
    tags: $tradableTag
    status: $status
  ) {
    id
    name
    namesAsMap
    isinCode
    securityCode
    updateCode3
    maturityDate
    classType1 {
      value
      __typename
    }
    country {
      id
      name
      code
      namesAsMap
      __typename
    }
    type {
      id
      name
      code
      namesAsMap
      __typename
    }
    currency {
      id
      securityCode
      __typename
    }
    managementFee
    managementFeePercentage
    minTradeAmount
    amountDecimalCount
    __typename
  }
}
`;

export interface TradableSecurityType {
  id: number;
  name: string;
  code: SecurityTypeCode;
  namesAsMap: Record<string, string>;
}

export interface TradableSecurity {
  id: number;
  name: string;
  namesAsMap: Record<string, string>;
  isinCode: string | null;
  securityCode: string;
  updateCode3: string | null;
  maturityDate: string | null;
  classType1: {
    value: string;
  };
  currency: {
    id: number;
    securityCode: string;
  };
  country: {
    id: number;
    code: string;
    name: string;
    namesAsMap: Record<string, string>;
  } | null;
  type: TradableSecurityType;
  managementFee: number;
  managementFeePercentage: number;
  minTradeAmount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  amountDecimalCount: number;
  isCblFund: boolean;
}

export interface TradableSecuritiesQuery {
  securities: TradableSecurity[];
}

interface SecurityFilterOption {
  id: string | null;
  label: string;
}

interface TradableSecuritiesFilters {
  country: Option;
  type: Option;
  name: string;
}

const filtersReducer = (
  filters: TradableSecuritiesFilters,
  newFilters: Partial<TradableSecuritiesFilters>
) => ({ ...filters, ...newFilters });

const emptyOption: SecurityFilterOption = {
  id: null,
  label: "-",
};

//initial values for filtering select & input components (empty)
const initialFilters = {
  country: emptyOption,
  type: emptyOption,
  name: "",
};

//initial selectable options for filtering select components (empty)
const filterOptionsInitial = {
  country: [emptyOption],
  type: [emptyOption],
};

export const useGetTradebleSecurities = (
  currencyCode?: string,
  tags?: string[]
) => {
  const { i18n } = useModifiedTranslation();
  const locale = i18n.language;
  const [filters, setFilters] = useReducer(filtersReducer, initialFilters);

  const { loading, error, data } = useQuery<TradableSecuritiesQuery>(
    TRADABLE_SECURITIES_QUERY,
    {
      variables: {
        status: "ACTIVE",
        countryCode: filters.country.id,
        securityType: filters.type.id,
        name: filters.name,
        tradableTag: tags ? [...tags, tradableTag] : [tradableTag],
      },
      ...getFetchPolicyOptions(
        `useGetTradebleSecurities.${filters.country.id}.${filters.type.id}.${filters.name}.${tags}`
      ),
    }
  );

  //derive the selectable options from the received security data, if any
  const filterOptions =
    data?.securities?.reduce((prev, curr) => {
      const securityCountry = curr.country;
      if (
        securityCountry &&
        !prev.country.some((country) => country.id === securityCountry.code)
      ) {
        prev.country.push({
          id: securityCountry.code,
          label: getBackendTranslation(
            securityCountry.name ?? "",
            securityCountry.namesAsMap,
            locale
          ),
        });
      }

      const securityType = curr.type;
      if (
        securityType &&
        !prev.type.some((type) => type.id === securityType.code)
      ) {
        prev.type.push({
          id: securityType.code,
          label: getBackendTranslation(
            securityType.name ?? "",
            securityType.namesAsMap,
            locale
          ),
        });
      }

      return prev;
    }, filterOptionsInitial) ?? filterOptionsInitial;

  return {
    loading,
    error,
    data: data?.securities,
    filters,
    setFilters,
    filterOptions,
    filtersEmpty: filters.country.id === null && filters.type.id === null && filters.name === "",
  };
};

export const useGetTradebleCblSecurities = (
    currencyCode?: string,
    tags?: string[]
) => {
  const { loading, error, data } = useQuery<TradableSecuritiesQuery>(
      TRADABLE_SECURITIES_CBL_QUERY,
      {
        variables: {
          status: "ACTIVE",
          tradableTag: [...(tags ?? []), tradableTag, cblPortalGroupTag],
        },
        ...getFetchPolicyOptions(
            `useGetTradebleSecuritiesOnly.${tags}`
        ),
      }
  );

  return {
    loading,
    error,
    data: data?.securities?.map((security) => ({ ...security, isCblFund: true })),
  };
};

export const useGetTradebleSecurityLazy = () => {
  const [error, setError] = useState<ApolloError | undefined>();
  const client = useApolloClient();

  const getTradableSecurity = async (variables: OperationVariables) => {
    try {
      const result = await client.query({
        query: TRADABLE_SECURITIES_QUERY,
        variables,
      });

      // Emulate an error for development
      // Uncomment below lines to emulate an error
      //const error = new ApolloError({ errorMessage: "Emulated error" });
      //throw error;

      setError(undefined);
      return result;
    } catch (err) {
      if (err instanceof ApolloError) setError(err);
    }
  };

  return {
    getTradableSecurity,
    error,
  };
};
