import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContractIdData } from "providers/ContractIdProvider";

export const useDetectActiveUserType = () => {
  const { data: contactData } = useGetContactInfo();
  const { selectedContactId } = useGetContractIdData();

  const isAnotherUser = (contactData?.contactId && selectedContactId) 
    ? contactData?.contactId?.toString() !== selectedContactId?.toString() : false

  //temporary the same
  const isAnotherPrivateUser = isAnotherUser && contactData?.juridicalCode !== "PE";
  const isAnotherLegalUser = isAnotherUser && contactData?.juridicalCode === "PE";

  return { isAnotherPrivateUser, isAnotherLegalUser, isAnotherUser }
};
