import { useParams } from "react-router-dom";
import {useGetContactInfo} from "../api/initial/useGetContactInfo";
import { TOTAL_INVESTMENTS_OPTION_ID } from "./useGetPortfolioOptions";

export const useGetAvailableAccountsCurrencies = () => {
    const { portfolioId: portfolioIdUrl } = useParams();
    const portfolioId = portfolioIdUrl ? parseInt(portfolioIdUrl, 10) : TOTAL_INVESTMENTS_OPTION_ID;

    const { data: contactInfo } = useGetContactInfo();

    // eslint-disable-next-line array-callback-return
    const portfolios = contactInfo?.portfolios.filter(portfolio => {
        if (portfolioId === 0 || portfolio.id.toString() === (portfolioId ?? '').toString()) {
            return portfolio;
        }
    });

    const accounts = portfolios?.map(portfolio => {
        return portfolio.accounts.map(account => {
            return account.accountName;
        })
    }).flat();

    // Extract unique currency codes 
    const availableCurrencies = (accounts || []).reduce((acc: string[], account) => {
        const currency = account.split('-').pop();
        if (currency && !acc.includes(currency)) {
            acc.push(currency);
        }
        return acc;
    }, []);

    return {
        availableCurrencies,
    };
};
