import {
  Portfolio,
  PortfolioGroups,
  useGetContactInfo,
} from "api/initial/useGetContactInfo";
import { PortfolioOption } from "components/PortfolioSelect/PortfolioSelect";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { useParams } from "react-router-dom";



export enum PermissionMode {
  ANY,
  SELECTED,
  SELECTED_ANY,
}

export class CBLAAMIds {
  static readonly IDS: number[] = [3, 7, 71, 72, 74, 79, 8, 80, 81, 83, 84, 85, 86, 87, 88];
}

export const isPortfolioInGroup = (
  portfolio: Portfolio,
  groupCode: PortfolioGroups
) => portfolio.portfolioGroups.some((group) => group.code === groupCode);

export const isPortfolioOptionInGroup = (
  portfolioOption: PortfolioOption,
  groupCode: PortfolioGroups
) => {
  const itCan =
    portfolioOption?.details &&
    isPortfolioInGroup(portfolioOption?.details, groupCode);
  if (itCan) return true;
  return false;
};

//Monthly investments
export const canPortfolioMonthlyInvest = (portfolio: Portfolio) => {
  return isPortfolioInGroup(portfolio, PortfolioGroups.MONTHLY_INVESTMENTS);
};

export const canPortfolioOptionMonthlyInvest = (
  portfolioOption: PortfolioOption
) => {
  return isPortfolioOptionInGroup(
    portfolioOption,
    PortfolioGroups.MONTHLY_INVESTMENTS
  );
};

//Monthly savings
export const canPortfolioMonthlySave = (portfolio: Portfolio) => {
  return isPortfolioInGroup(portfolio, PortfolioGroups.MONTHLY_SAVINGS);
};

export const canPortfolioOptionMonthlySave = (
  portfolioOption: PortfolioOption
) => {
  return isPortfolioOptionInGroup(
    portfolioOption,
    PortfolioGroups.MONTHLY_SAVINGS
  );
};

//Trading
export const canPortfolioTrade = (portfolio: Portfolio) => {
  return isPortfolioInGroup(portfolio, PortfolioGroups.TRADE);
};

export const canPortfolioOptionTrade = (portfolioOption: PortfolioOption) => {
  return isPortfolioOptionInGroup(portfolioOption, PortfolioGroups.TRADE);
};

export const tradableTag = "Tradeable";
export const switchableTag = "Switchable";
export const cblPortalGroupTag = "CBLPortalGroup";

export const useIsCBLAAMPortfolio = () => {
  const { linkedContact } = useKeycloak();
  const { selectedContactId } = useGetContractIdData();
  const { portfolioId } = useParams();

  const { data: contactData } = useGetContactInfo(
    false,
    selectedContactId || linkedContact
  );

  const selectedPortfolio = contactData?.portfolios.filter(
    (portfolio) =>
      portfolioId !== undefined && portfolio.id === parseInt(portfolioId, 10)
  );

  if (selectedPortfolio && selectedPortfolio.length) {
    const porfolio = selectedPortfolio[0];
    const lastIndex = porfolio.shortName.lastIndexOf("-");

    if (lastIndex !== -1) {
      const activeId = porfolio.shortName.substring(lastIndex + 1);

      return CBLAAMIds.IDS.includes(parseInt(activeId))
    }
  }

  return false;
};

export const filterCBLAAMPortfolios = (portfolios: object) => {

  const portfolioItems = Object.values(portfolios);

  // Filter out portfolio items where the shortName ends with a value in CBLAAMIds.IDS
  const filteredPortfolios = portfolioItems.filter((portfolio) => {
    const shortName = portfolio.details.shortName;
    const lastIndex = shortName.lastIndexOf("-");

    if (lastIndex !== -1) {
      // Extract the substring after the last dash
      const activeId = parseInt(shortName.substring(lastIndex + 1));

      // Check if the activeId is in CBLAAMIds.IDS
      return !CBLAAMIds.IDS.includes(activeId);
    }

    // If there is no dash, include the portfolio item
    return true;
  });

  // Return the filtered array of portfolios
  return filteredPortfolios;
};


/*
 * Checks if user or portfolio is eligible
 * @param mode: mode to apply when checking if eligible
 * SELECTED - check only the selected portfolio
 * ANY - check any of the user's portfolios
 * SELECTED_ANY - use SELECTED_ONLY if there is a selected portfolio, else do ANY
 * @param filterFunction: predicate function that can return false or true for a given Portfolio
 * @return boolean - whether user can
 */
export const usePermission = (
  mode = PermissionMode.SELECTED,
  filterFunction: (portfolio: Portfolio) => boolean
) => {
  const { portfolioId } = useParams();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );

  




  const doesAnyPortfolioHavePermission = portfolios.some((p) =>
    filterFunction(p)
  );
  const selectedPortfolio = portfolios.filter(
    (portfolio) =>
      portfolioId !== undefined && portfolio.id === parseInt(portfolioId, 10)
  );
  const doesSelectedPortfolioHavePermission = selectedPortfolio.some((p) =>
    filterFunction(p)
  );


  switch (mode) {
    case PermissionMode.ANY:
      return doesAnyPortfolioHavePermission;
    case PermissionMode.SELECTED:
      return doesSelectedPortfolioHavePermission;
    case PermissionMode.SELECTED_ANY:
      if (portfolioId !== undefined) return doesSelectedPortfolioHavePermission;
      return doesAnyPortfolioHavePermission;
    default:
      return false;
  }
};
