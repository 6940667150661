import { SecurityClassType1, SecurityTypeCode } from "api/holdings/types";
import { FinancialProfile } from "api/trading/useGetFinancialProfile";
import { Button } from "components/Button/Button";
import { useExternalUrls } from "hooks/useExternalUrls";
import { useGetAvailableAccountsCurrencies } from "hooks/useGetAvailableAccountsCurrencies";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";


type TradingActionButtonProps = {
    size: string;
    disabled?: boolean;
    isLoading?: boolean;
    buyLabel: string;
    testLabel: string;
    onClick?: (e: { stopPropagation: () => void; }) => void;
    userClassification?: string | undefined;
    securityType?: string | undefined;
    securityClassType1?: string | undefined;
    currencySecurityCode?: string | undefined;
    financialContactProfile?: FinancialProfile | undefined | null;
    uniqueCurrencies?: string[] | undefined | null;
};

enum EButtonAction {
    BUY = "BUY",
    FILL_THE_TEST = "FILL_THE_TEST",
    EXCHANGE = "EXCHANGE"
}

const TradingActionButton = ({
    size,
    disabled,
    isLoading,
    buyLabel,
    testLabel,
    onClick,
    userClassification,
    securityType,
    securityClassType1,
    currencySecurityCode,
    financialContactProfile,
    uniqueCurrencies
}: TradingActionButtonProps) => {
    const { t } = useModifiedTranslation();

    const { fillTest, exchangeUrl } = useExternalUrls();

    const { availableCurrencies } = useGetAvailableAccountsCurrencies();

    const openFillTestExternalUrl = () => {
        window.location.replace(fillTest || "");
    }

    const openExchangeExternalUrl = () => {
        window.location.replace(exchangeUrl || "");
    }

    let action = '';

    if (userClassification === 'PROF') {
        action = EButtonAction.BUY;
    } else {
        if (securityType && ['C', 'E'].includes(securityType)) {
            action = EButtonAction.BUY;
        } else if (securityType && securityType === SecurityTypeCode.ETF) {

            if (securityClassType1 === SecurityClassType1.NON_COMPLEX) {
                action = EButtonAction.BUY;
            } else if (securityClassType1 === SecurityClassType1.COMPLEX) {
                action = EButtonAction.FILL_THE_TEST;

                if (financialContactProfile?.etfExpiryDate?.dateValue
                    && new Date(financialContactProfile?.etfExpiryDate?.dateValue) >= new Date()
                ) {
                    action = EButtonAction.BUY;
                }
            }
        } else if (securityType && securityType === SecurityTypeCode.DEBT_INSTRUMENT) {

            if (securityClassType1 === SecurityClassType1.NON_COMPLEX) {
                action = 'BUY';
            } else if (securityClassType1 === SecurityClassType1.COMPLEX) {
                action = EButtonAction.FILL_THE_TEST;

                if (financialContactProfile?.derivativesTestResult?.stringValue
                    && financialContactProfile?.riskUnderstTestResult?.stringValue
                ) {
                    if (financialContactProfile.derivativesExpiryDate?.dateValue
                        && financialContactProfile.riskUnderstExpiryDate?.dateValue
                        && new Date(financialContactProfile?.derivativesExpiryDate?.dateValue) >= new Date()
                        && new Date(financialContactProfile?.riskUnderstExpiryDate?.dateValue) >= new Date()
                    ) {
                        action = EButtonAction.BUY;
                    }
                }
            }
        }
    }

    const currencies = uniqueCurrencies && uniqueCurrencies.length > 0 ? uniqueCurrencies : availableCurrencies;

    if (action === EButtonAction.BUY) {
        if (currencySecurityCode && currencies.includes(currencySecurityCode)) {
            return <Button
                onClick={onClick}
                isFullWidth
                size={size as "md" | "xs"}
                disabled={disabled}
                isLoading={isLoading}
            >
                {buyLabel}
            </Button>
        }

        return <Button
            isFullWidth
            size={size as "md" | "xs"}
            onClick={openExchangeExternalUrl}
        >
            { t("exchange") }
        </Button>


    } else if (action === EButtonAction.FILL_THE_TEST) {
        return <Button
            isFullWidth
            size={size as "md" | "xs"}
            onClick={openFillTestExternalUrl}
        >
            {testLabel}
        </Button>
    } else {
        return <span></span>;
    }
};

export default TradingActionButton;
